import clsx from "classnames";
import classes from "./styles.module.scss";
import { Button, Container } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { DynamicHtml } from "@/ui/DynamicHtml";
import { useLocale } from "@/hooks/useLocale";
import { Heading } from "@/ui/Heading";
import { useLink } from "@/hooks/useLink";
import VisibilitySensor from "react-visibility-sensor";
import { useState } from "react";
import Image from "@/ui/Image";

export const SolutionsSection = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const locale = useLocale();
  const link = useLink();

  return (
    <VisibilitySensor
      partialVisibility={true}
      onChange={(v) => {
        if (v) {
          setVisible(true);
        }
      }}
    >
      <div className={className}>
        <Container>
          <Heading className="mb-5">
            <DynamicText path={`section.solutions.title.${locale}`} tag="h2" />
          </Heading>
          <div className={clsx(classes.solutions, { visible }, "py-md-5 pe-md-5")}>
            <Image
              className={classes.solutions__bg}
              img={{ path: "section.solutions.bg", src: "/assets/about_us/hiring_solutions/1.jpg" }}
              size="1274x"
              alt=""
            />
            <div className="py-5">
              <div className={clsx(classes.card, "p-4 mb-2")}>
                <div className="flex-grow-1">
                  <DynamicText path={`section.solutions.contract.title.${locale}`} className="mb-4" tag="h5" />
                  <DynamicHtml path={`section.solutions.contract.text.${locale}`} className="me-4 mb-4" />
                </div>
                <div>
                  <Button variant="outline-primary" href={link("hiring.contract")} />
                </div>
              </div>
              <div className={clsx(classes.card, "p-4")}>
                <div className="flex-grow-1">
                  <DynamicText path={`section.solutions.permanent.title.${locale}`} className="mb-4" tag="h5" />
                  <DynamicHtml path={`section.solutions.permanent.text.${locale}`} className="me-4 mb-4" />
                </div>
                <div>
                  <Button variant="outline-primary" href={link("hiring.permanent")} />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </VisibilitySensor>
  );
};
